import { motion } from "framer-motion";
import React from "react";
export default function Companies() {
  return (
    <section className="w-[100vw] h-[100%] bg-[#006abd] text-white items-center flex justify-center overflow-hidden">
      <div className="max-w-[1400px] w-[100%] flex px-[2rem] py-[5rem] ">
        <div className="w-[100%] flex flex-col gap-5">
          <h1 className="text-2xl font-bold uppercase text-center">
            120+ Brand Company Collaboration with me.
          </h1>
          <div className="grid w-[100%] grid-cols-5 gap-y-10 mt-[2rem]">
            <motion.img
            
            whileHover={{
                scale: [1, 1.4, 1],
                transition:{
                    repeatType:'loop',
                    repeat:Infinity,
                    ease:'anticipate',
            
                    duration: 0.8,
                }
            }}
          
              src={require("./Images/logo-3.png")}
              alt="comedy"
              className="w-[60%] opacity-40 hover:opacity-100  transition-all duration-200"
            />
            <motion.img
            
            whileHover={{
                scale: [1, 1.4, 1],
                transition:{
                    repeatType:'loop',
                    repeat:Infinity,
                    ease:'anticipate',
            
                    duration: 0.8,
                }
            }}
          
              src={require("./Images/logo-3.png")}
              alt="comedy"
              className="w-[60%] opacity-40 hover:opacity-100  transition-all duration-200"
            />
             <motion.img
            
            whileHover={{
                scale: [1, 1.4, 1],
                transition:{
                    repeatType:'loop',
                    repeat:Infinity,
                    ease:'anticipate',
            
                    duration: 0.8,
                }
            }}
          
              src={require("./Images/logo-3.png")}
              alt="comedy"
              className="w-[60%] opacity-40 hover:opacity-100  transition-all duration-200"
            />
             <motion.img
            
            whileHover={{
                scale: [1, 1.4, 1],
                transition:{
                    repeatType:'loop',
                    repeat:Infinity,
                    ease:'anticipate',
            
                    duration: 0.8,
                }
            }}
          
              src={require("./Images/logo-3.png")}
              alt="comedy"
              className="w-[60%] opacity-40 hover:opacity-100  transition-all duration-200"
            />
             <motion.img
            
            whileHover={{
                scale: [1, 1.4, 1],
                transition:{
                    repeatType:'loop',
                    repeat:Infinity,
                    ease:'anticipate',
            
                    duration: 0.8,
                }
            }}
          
              src={require("./Images/logo-3.png")}
              alt="comedy"
              className="w-[60%] opacity-40 hover:opacity-100  transition-all duration-200"
            />
             <motion.img
            
            whileHover={{
                scale: [1, 1.4, 1],
                transition:{
                    repeatType:'loop',
                    repeat:Infinity,
                    ease:'anticipate',
            
                    duration: 0.8,
                }
            }}
          
              src={require("./Images/logo-3.png")}
              alt="comedy"
              className="w-[60%] opacity-40 hover:opacity-100  transition-all duration-200"
            />
             <motion.img
            
            whileHover={{
                scale: [1, 1.4, 1],
                transition:{
                    repeatType:'loop',
                    repeat:Infinity,
                    ease:'anticipate',
            
                    duration: 0.8,
                }
            }}
          
              src={require("./Images/logo-3.png")}
              alt="comedy"
              className="w-[60%] opacity-40 hover:opacity-100  transition-all duration-200"
            />
             <motion.img
            
            whileHover={{
                scale: [1, 1.4, 1],
                transition:{
                    repeatType:'loop',
                    repeat:Infinity,
                    ease:'anticipate',
            
                    duration: 0.8,
                }
            }}
          
              src={require("./Images/logo-3.png")}
              alt="comedy"
              className="w-[60%] opacity-40 hover:opacity-100  transition-all duration-200"
            />
             <motion.img
            
            whileHover={{
                scale: [1, 1.4, 1],
                transition:{
                    repeatType:'loop',
                    repeat:Infinity,
                    ease:'anticipate',
            
                    duration: 0.8,
                }
            }}
          
              src={require("./Images/logo-3.png")}
              alt="comedy"
              className="w-[60%] opacity-40 hover:opacity-100  transition-all duration-200"
            />
             <motion.img
            
            whileHover={{
                scale: [1, 1.4, 1],
                transition:{
                    repeatType:'loop',
                    repeat:Infinity,
                    ease:'anticipate',
            
                    duration: 0.8,
                }
            }}
          
              src={require("./Images/logo-3.png")}
              alt="comedy"
              className="w-[60%] opacity-40 hover:opacity-100  transition-all duration-200"
            />
       
          </div>
        </div>
      </div>
    </section>
  );
}
