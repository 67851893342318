import React from 'react'
import Head from '../Head'
import Banner from '../Contact/Banner'
import NewsLetter from '../Sessions/NewsLetter'
import Footers from '../Footers'
import About from './About'

export default function BlogDetail() {
  return (
    <>
     <Head/>
     <Banner name={'ARTICLE & NEWS'}  title={' BLOG NAME'}/>
     <About/>
     <NewsLetter/>
     <Footers/>
    </>
  )
}
