import React from 'react'
import { AiFillInstagram } from 'react-icons/ai';
import { FaFacebook, FaYoutube } from "react-icons/fa";
export default function Banner() {
  return (
    <section className='w-[100vw] text-white  h-[44rem] banner items-center justify-center overflow-x-hidden'>
      <div className='w-[100%] bg-[rgba(0,107,189,0.45)] h-[100%] flex items-center justify-center'>
      <div className='w-[100%] max-w-[1400px] px-[5%] h-[100%] py-[4rem] pt-[5rem]'>
        <div className='relative w-[100%] flex flex-col items-center justify-center'>
            <div className='absolute right-[7%] bottom-[2rem] '>
                <div className='flex text-3xl flex-col gap-5'>
                <FaFacebook />
                <AiFillInstagram />
                <FaYoutube />
                </div>

            </div>
            <h1 className='text-2xl font-bold tracking-wider  uppercase'>welcome my official website</h1>
                <h1 className='text-[9rem] font-bold  uppercase'>
                    SPot light
                </h1>
                <p className='text-lg tracking-wider text-center w-[80%]'>Justo nec ultrices dui sapien eget mi proin. Sed velit dignissim sodales ut eu sem integer vitae justo. Sit amet nisl purus in mollis nunc sed. Sit amet justo donec enim diam vulputate ut.</p>
                <div className='border-[0.1px] cursor-pointer mt-[2rem] rounded-lg px-4 py-3 text-md tracking-wider uppercase bg-white text-[#006bbd] hover:border-white  
                hover:text-white transition-all  duration-150 hover:bg-[#0094F6] border-black '>
                    Discover Now
                </div>
        </div>
      </div>
      </div>
    </section>
  )
}
