import React from 'react'
import { FaNewspaper } from 'react-icons/fa'
import Blog from './Ui/Blog'

export default function Blogs() {
  return (
    <section className='w-[100vw] h-[100%] flex items-center justify-center overflow-hidden'>
      <div className='w-[100%] max-w-[1400px] px-[2rem] py-[4rem]'>
        <div className='w-[100%] flex flex-col gap-5'>
            <div className='w-[100%] flex gap-2 items-center justify-center '><FaNewspaper className='text-4xl' />
                <h1 className='text-2xl font-bold tracking-wider uppercase'>news & blog</h1>
            </div>
            <div className='text-5xl uppercase text-center flex gap-2 w-[100%] font-bold items-center justify-center'>
                <h1>Funny</h1><span className='text-blue-500'>News</span>
            </div>
            <div className='w-[100%] grid mt-[3rem] grid-cols-3 gap-5'>
              <a href="/blog/1">
                <Blog title="Bated breath while watch very exciting football"/>
              </a>
              <a href="/blog/1">
                <Blog title='Happy young smart bloggers filming new vlog video'/>
              </a><a href="/blog/1">
                <Blog title='Friends having party in a nightclub'/>
              </a>
            </div>
        </div>
      </div>
    </section>
  )
}
