import * as React from "react";
import { styled } from "@mui/material/styles";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
  backgroundColor: "transparent",
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    //   backgroundColor: expanded
    //     ? 'rgba(255, 255, 255, .05)'
    //       : 'rgba(0, 0, 0, .03)',
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "0px solid rgba(0, 0, 0, .125)",
  backgroundColor: "transparent",
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="flex flex-col gap-5">
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        className="mb-2 bg-transparent"
      >
        <AccordionSummary
          style={{
            backgroundColor: expanded === "panel1" ? "#3b82f680" : "white",
            color: expanded === "panel1" ? "white" : "#252525",
            borderRadius: "10px",
          }}
          className={`${
            expanded === "panel1" ? "bg-[#960101]" : "bg-green-500"
          } w-[100%] h-[100%] bg-red-700`}
        >
          <h1 className="text-semibold text-[1.2rem] tracking-wide py-2 font-semibold ">
            What type of events do you perform at?
          </h1>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non
            lacus quam. Donec est velit, condimentum vitae tempor eget, pretium
            et massa. Integer velit dui, lacinia non turpis at, lobortis
            tincidunt risus. Donec ut cursus urna. Praesent luctus interdum
            ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        className="mb-2"
      >
        <AccordionSummary
          style={{
            backgroundColor: expanded === "panel2" ? "#3b82f680" : "white",
            color: expanded === "panel2" ? "white" : "#252525",
            borderRadius: "10px",
          }}
          className={`${
            expanded === "panel2" ? "bg-green-500" : "bg-green-500"
          } w-[100%] h-[100%] bg-red-700`}
        >
          <h1 className="text-semibold text-[1.2rem] tracking-wide py-2 font-semibold">
          How long is your performance?
          </h1>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non lacus quam. Donec est velit, condimentum vitae tempor eget, pretium et massa. Integer velit dui, lacinia non turpis at, lobortis tincidunt risus. Donec ut cursus urna. Praesent luctus interdum ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          style={{
            backgroundColor: expanded === "panel3" ? "#3b82f680" : "white",
            color: expanded === "panel3" ? "white" : "#252525",
            borderRadius: "10px",
          }}
          className={`${
            expanded === "panel3" ? "bg-green-500" : "bg-green-500"
          } w-[100%] h-[100%] bg-red-700`}
        >
          <h1 className="text-semibold text-[1.2rem] tracking-wide py-2 font-semibold">
          Do you travel for performances?
          </h1>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non lacus quam. Donec est velit, condimentum vitae tempor eget, pretium et massa. Integer velit dui, lacinia non turpis at, lobortis tincidunt risus. Donec ut cursus urna. Praesent luctus interdum ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
        className="mt-2"
      >
        <AccordionSummary
          style={{
            backgroundColor: expanded === "panel4" ? "#3b82f680" : "white",
            color: expanded === "panel4" ? "white" : "#252525",
            borderRadius: "10px",
          }}
          className={`${
            expanded === "panel4" ? "bg-green-500" : "bg-green-500"
          } w-[100%] h-[100%] bg-blue-700`}
        >
          <h1 className="text-semibold  text-[1.2rem] tracking-wide py-2 font-semibold">
          How can I book you for an event?
          </h1>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam non lacus quam. Donec est velit, condimentum vitae tempor eget, pretium et massa. Integer velit dui, lacinia non turpis at, lobortis tincidunt risus. Donec ut cursus urna. Praesent luctus interdum ante. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            scheduling.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
