import React from 'react'
import Head from '../Head'
import Banner from '../Contact/Banner'
import Tours from '../Tours'
import Companies from '../Companies'
import Footers from '../Footers'
import Event from './Event'

export default function Schedules() {
  return (
    <>
      <Head/>
      <Banner name={'SCHEDULE'} title={'SCHEDULE'}/>
      <Tours/>
      <Event/>
      <Companies/>
      <Footers/>
    </>
  )
}
