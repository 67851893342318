import React, { useState } from 'react'
import Head from '../Head'
import Banner from '../Contact/Banner'
import About from '../About'
import Services from '../Services'
import Why from '../Why'
import Testinomials from '../Testinomials'
import Companies from '../Companies'
import Footers from '../Footers'
import Ab from './Ab'
import VideoPlayer from './VideoPlayer'

export default function Abouts() {
    const [video,setVideo]=useState(true)
  return (
    <>
     <Head/>
     <Banner name={'About'} title={'About'}/> 
     <About/>
     <Ab setVideo={setVideo}/>
    {video && <VideoPlayer  setVideo={setVideo}/>}
     <Services/>
     <Why/>
     <Testinomials/>
     <Companies/>
     <Footers/>
    </>
  )
}
