import React from 'react'
import { FaHiking, FaPlus } from 'react-icons/fa'
import Tour from './Ui/Tour'

export default function Tours() {
  return (
    <section className='w-[100vw] h-[100%] text-[#252525] flex items-center justify-center overflow-hidden'>
        <div className='max-w-[1400px] w-[100%] px-[2rem] py-[3rem] '>
            <div className='w-[100%] items-center gap-10 flex'>
                <div className='w-[45%] flex flex-col gap-5'>
                    <div className='flex tracking-wider text-2xl uppercase items-center gap-2 font-bold'>
                    <FaHiking className='text-3xl' /><h1>
                        tour dates</h1>

                    </div>
                    <div className='flex text-[2.8rem] font-bold tracking-wider leading-[3rem] flex-col'>
                        <h1>Makes Me Laugh</h1>
                        <h1 className='text-blue-500'>in Everytime!</h1>
                    </div>
                    <p>Dui nunc mattis enim ut tellus. Viverra maecenas accumsan lacus vel facilisis volutpat est velit. Ante in nibh mauris cursus mattis. Consequat semper viverra nam libero.</p>
                    <div className="hover:bg-blue-500 border-[0.2px] mt-[1rem] hover:text-white border-transparent border-blue-500  bg-transparent text-blue-500 rounded-md transition-all duration-200 cursor-pointer  px-5 py-2 w-fit text-lg font-bold tracking-wider flex items-center gap-2 uppercase">
                <h1>Read More</h1>
                <FaPlus />
              </div>
              <Tour color={'bg-[#2894e8]'} title="Comedy Exist Performance" />
              <Tour color={"bg-[#ff685d]"} title="Live Stand-Up Comedy Performances" />
              <Tour color={"bg-[#FED32E]"} title="There's just me & you Performance" />
                </div>
                <div className='w-[50%] relative h-[100%] flex items-center justify-center '>
                    <img src={require('./Images/background-2-1.png')} alt="comedy" className='w-[70%] absolute z-[-10]' />
                    <div className='w-[100%] flex gap-4 items-end'>
                        <div className='flex flex-col gap-3   w-[50%] rounded-xl   overflow-hidden'>
                            <img src={require('./Images/photo-5-1-1536x1024.jpg')} alt=" comedy" className='w-[100%] rounded-xl' />
                            <div className='w-[100%] mt-[0rem] flex items-center gap-3 bg-slate-100 border-gray-100 rounded-xl px-5 py-3'>
                                <img src={require('./Images/icon-5.png')} alt="comedy" className='w-[4rem] ' />
                                <div>
                                    <h1 className='text-blue-500 font-bold tracking-wide text-2xl  leading-[1.5rem]'>COMEDY <br /> AWARDS</h1>
                                    <h1 className='text-lg'>Favorite 2024</h1>
                                </div>
                            </div>
                        </div>
                        <img src={require('./Images/photo-4-2.jpg')} alt=" comedy" className='w-[50%] rounded-xl' />

                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
