import React from "react";
import { FaPlay } from "react-icons/fa";
import { LuCalendarClock } from "react-icons/lu";

export default function Shows({setVideo}) {
  return (
    <div className="w-[100%] flex gap-3  border-[1px] border-gray-300 p-[1rem] rounded-xl ">
      <div className="w-[30%] flex items-center justify-center relative h-[7rem] rounded-lg overflow-hidden">
        <img
          src={require("../Images/photo-5-1-1536x1024.jpg")}
          className="w-[100%] h-[100%] object-cover "
          alt=""
        />
        <div
          onClick={() => setVideo(() => true)}
          className="text-white cursor-pointer absolute text-[1rem] flex items-center justify-center w-[3rem] h-[3rem] rounded-full border-[3px] border-white"
        >
          <FaPlay />
        </div>
      </div>

      <div className="flex w-[70%] flex-col gap-3">
        <h1 className="text-lg font-bold">
          sed do eiusmod tempor incididunt ut labore et dolore.
        </h1>
        <div className="flex items-center gap-2">
          <LuCalendarClock />
          <h1 className="text-md uppercase font-bold">24 August 2024</h1>
        </div>
      </div>
    </div>
  );
}
