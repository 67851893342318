import React from 'react'
import { FaFacebook, FaStar, FaTelegram, FaUserAlt, FaYoutube } from 'react-icons/fa'
import { FaSquareInstagram } from 'react-icons/fa6'
import { IoLogoTwitter } from 'react-icons/io'

export default function card({name,title}) {
  return (
    <div className='w-[100%] border-[1px] border-blue-300 bg-blue-200 p-[1.5rem] rounded-2xl'>
      <div className='w-[100%] flex flex-col gap-3'>
        <div className='rounded-2xl overflow-hidden'>
            <img src={require('../Images/photo-6-1536x1228.jpg')} alt="" />

        </div>
        <div className='flex w-[100%] justify-between items-center'>
        <div className='text-xl font-semibold flex items-center gap-2'>
        <FaUserAlt  className='text-purple-600'/> <h1 >{name}</h1>
        </div>
        <div>

        <div className='text-[#d3c32f] flex gap-1 text-xl'>
        <FaStar  />
        <FaStar  />
        <FaStar  />
        <FaStar  />
        <FaStar  />
        </div>
        <h1 className='text-lg uppercase tracking-wider'>( 4.8 Review ) </h1>
        </div>
        </div>
        <h1 className='text-2xl font-semibold leading-[1.7rem]'>{title}</h1>
        <p className='text-md tracking-wider leading-[1.5rem]'> 
        Risus feugiat in ante metus dictum at tempor commodo. Quis risus sed vulputate odio.
        </p>
        <div className='mt-3 bg-blue-500 text-xl flex gap-7 items-center w-[100%] justify-center rounded-xl text-white py-3'>
            <span>
            <FaSquareInstagram />
            </span>
            <span><FaTelegram /></span>
            <span>
            <FaFacebook />
            </span>
            <span>
            <IoLogoTwitter />
            </span>
        </div>
      </div>
    </div>
  )
}
