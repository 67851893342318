import React from "react";
import { FaPhotoFilm } from "react-icons/fa6";
import Card from "./Ui/Card";
export default function Anchors() {
  return (
    <section className="w-[100vw] h-[100%] flex bg-blue-100 text-[#252525] items-center justify-center overflow-hidden">
      <div className="w-[100%] h-[100%] max-w-[1400px] px-[2rem] py-[3rem]  ">
        <div className="w-[100%] items-center justify-center text-center flex flex-col gap-3">
          <div className="text-2xl gap-2 font-semibold justify-center uppercase tracking-wider flex items-center ">
            <FaPhotoFilm className="text-4xl" /> <h1>video update</h1>
          </div>
          <div className="text-[50px] flex gap-2  font-bold">
            <h1>Explore interesting &</h1>
            <span className="text-blue-500">Entertaining Sessions.</span>
          </div>
          <p className="w-[60%] text-lg tracking-wider text-[#474747]">
            Rhoncus est pellentesque elit ullamcorper dignissim cras tincidunt.
            Duis tristique sollicitudin nibh sit amet commodo nulla facilisi
            nullam. Neque sodales ut etiam sit.
          </p>
        </div>
        <div className="w-[100%] mt-[2rem] grid gap-y-10 grid-cols-3 gap-5">
          <a href="/show/1">
          <Card name="ANDREW DOE" title='Laugh with your father and father'/>
          </a>
          <a href="/show/1">
          <Card name="ANDREW DOE" title='Laugh with your father and father'/>
          </a>
          <a href="/show/1">
          <Card name="ANDREW DOE" title='Laugh with your father and father'/>
          </a>
          <a href="/show/1">
          <Card name="ANDREW DOE" title='Laugh with your father and father'/>
          </a>
          <a href="/show/1">
          <Card name="ANDREW DOE" title='Laugh with your father and father'/>
          </a>
          <a href="/show/1">
          <Card name="ANDREW DOE" title='Laugh with your father and father'/>
          </a>
        </div>
      </div>
    </section>
  );
}
