import { motion } from 'framer-motion'
import React from 'react'
import { FaRegCalendarAlt, FaRegUserCircle } from 'react-icons/fa'

export default function Blog({title}) {
  return (
    <div className='w-[100%] p-[1rem] border-[#a8a7a7] border-[1px] rounded-2xl bg-slate-200'>
        <div className='w-[100%] flex flex-col gap-4'>
            <div className='rounded-2xl relative '>
                <div style={{zIndex:9999}} className='absolute bottom-[-1rem] right-[5%] leading-[0.6rem] bg-blue-500 p-2 rounded-md flex flex-col items-center justify-center' >
                    <h1 className='text-lg leading-[0.6rem] font-bold text-white'>20</h1>
                    <span className='text-xs font-bold text-white uppercase'>mar</span>
                </div>
                <div className='w-[100%] overflow-hidden rounded-xl'>

                    <motion.img whileHover={{scale:1.1,zIndex:0,
                        transition:{
                            duration:1,
                            ease:'easeIn',
                            type:'tween'
                        }
                    }} src={require('../Images/blog-2-2.jpg')} alt="comdemy" className='-z-10' />
                </div>
            </div>
            <div className='w-[100%] flex  items-center justify-center gap-4'>
                        <div className='flex items-center gap-2'>
                        <FaRegUserCircle  className='text-blue-500 font-light uppercase tracking-wider'/>
                            <h1 className='text-blue-500 font-light uppercase tracking-wider'>
                            hendrik</h1>
                        </div>
                        <div className='flex items-center gap-2'>
                        <FaRegCalendarAlt
                        className='text-gray-400 font-light uppercase tracking-wider'/>
                            <h1 className='text-gray-500 font-light uppercase tracking-wider'>         
                                March 31, 2024</h1>
                        </div>
            </div>
            <div className='w-[100%] mb-[1rem] flex flex-col gap-7 justify-center items-center'>
                <h1 className='text-2xl w-[87%] font-bold text-center'>
                {title}
                </h1>
                <p className='w-[80%] text-center'>HOME – ARTICLE & NEWS SINGLE BLOG Bated breath while watch very exciting football Admin 2024/03/31 Sport Dolor sed viverra…</p>
                <div className="hover:bg-blue-500 bg-gray-600 border-[1px] mt-[1rem] hover:text-white  border-blue-500  bg-transparent text-blue-500 rounded-md transition-all duration-200 cursor-pointer  px-5 py-2 w-fit text-lg font-bold tracking-wider flex items-center gap-2 uppercase">
                <h1>Read More</h1>
                
              </div>
            </div>

        </div>
      
    </div>
  )
}
