import React from 'react'
import { FaFacebook, FaTelegram } from 'react-icons/fa'
import { FaSquareInstagram } from 'react-icons/fa6'
import { IoLogoTwitter } from 'react-icons/io'

export default function About() {
  return (
    <section className='w-[100vw] text-white flex items-center justify-center overflow-hidden'>
        <div className='w-[100%] max-w-[1400px]  px-[2rem]  pt-[8rem]'>

            <div className='w-[100%] bg-blue-400 relative px-[3rem] items-center flex-col gap-5 rounded-xl justify-center flex pt-[7rem] py-[2rem]  '>
                <div className='w-[100%]  flex items-center justify-center top-[-15%] absolute'>
                    <div className='w-[10%] border-[3px] border-white overflow-hidden rounded-t-full rounded-b-full h-[10rem]'>
                        <img src={require('../Images/photo-4-2.jpg')} alt="" />
                    </div>
                </div>
                <img src={require('../Images/ttd.png')} className='w-[8rem]' alt="" />
                <h1 className='text-5xl font-bold  '>IM' ZEN EVANA</h1>
                <p className='w-[80%] text-center'>
                Amet risus nullam eget felis eget nunc. Nulla pharetra diam sit amet nisl. Suscipit adipiscing bibendum est ultricies. Pellentesque habitant morbi tristique senectus et. Ut ornare lectus sit amet est placerat in egestas. Nulla posuere sollicitudin aliquam ultrices sagittis orci a scelerisque purus. Velit aliquet sagittis id consectetur purus. Fermentum et sollicitudin ac orci phasellus. In nibh mauris cursus mattis molestie a iaculis at erat.
                </p>
                <p className='w-[80%] text-center'>
                Enim ut sem viverra aliquet eget sit amet. Scelerisque purus semper eget duis at tellus at urna condimentum. Vel pharetra vel turpis nunc eget lorem dolor sed. Facilisis gravida neque convallis a cras semper auctor neque. Molestie at elementum eu facilisis sed.
                </p>
                <div className='flex flex-col gap-4 mt-3 w-[100%] items-center justify-center '>
                    <h1 className='text-xl font-bold'>SOCIAL MEDIA</h1>
                    <div className="-mt-2  text-xl flex gap-7 rounded-xl text-white py-3">
              <span>
                <FaSquareInstagram />
              </span>
              <span>
                <FaTelegram />
              </span>
              <span>
                <FaFacebook />
              </span>
              <span>
                <IoLogoTwitter />
              </span>
            </div>
                </div>
            </div>
        </div>
      
    </section>
  )
}
