import React from 'react'

export default function Banner({name,title}) {
  return (
    <section className='w-[100vw] bg-blue-600 text-white flex items-center justify-center overflow-hidden '>
      <div className='w-[100%] relative flex-col gap-5 max-w-[1400px] py-[4rem] flex items-center justify-center'>
          <img src={require('../Images/background-2-1.png')} className='
          absolute right-[-10%] w-[30%]
          ' alt="" />
            <h1 className='uppercase text-lg tracking-wide '>
                Home- {name}
            </h1>
            <span className='text-8xl font-bold'>
            {title}
            </span>
      </div>
    </section>
  )
}
