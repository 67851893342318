import React from 'react'

export default function Head() {
  return (
    <header className='w-[100vw] h-[100%] text-white border-b-[1px] border-white bg-[#007aff] items-center flex justify-center overflow-hidden'>
        <div className='w-[100%] max-w-[1400px] flex items-center px-[3rem] py-5 justify-center '>
            <div className='w-[100%] flex justify-between items-center'>
                <div>
                    <h1 className='text-4xl font-bold'>
                        SPOTLINGHT
                    </h1>
                </div>
                <div className='text-[17px] flex gap-8 uppercase items-center'>
                    <a href="/" className='font-bold tracking-wider '> Home</a>
                    <a href="/about" className=' font-bold tracking-wider '> About</a>

                    <a href="/schedule" className=' font-bold tracking-wider '> Schedule</a>
                    <a href="/show" className=' font-bold tracking-wider '> Our Shows</a>
                    <a href="/blog" className=' font-bold tracking-wider '> Blog</a>
                    <a href="contact" className=' font-bold tracking-wider '> Contact Us</a>
                    <div className='bg-[#63a4eb] hover:bg-[#406aa1e3] transition-all duration-100  border-white font-bold px-7 py-2 rounded-md uppercase  tracking-wider'>
                        <a href="/contact">
                        <span>Get a  Quote</span>
                        </a>
                    </div>
                </div>
            </div>

        </div>
      
    </header>
  )
}
