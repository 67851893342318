import React from 'react'
import Head from '../Head'
import Banner from '../Contact/Banner'
import Events from '../Events'
import Footers from '../Footers'
import Anchors from '../Anchors'
import NewsLetter from './NewsLetter'

export default function Session() {
  return (
    <>
      <Head/>
      <Banner name={'LATEST SHOW'}     title={'SHOW'}/>
      <Anchors/>
      <NewsLetter/>
      <Footers/>
    </>
  )
}
