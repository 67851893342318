import React from 'react'
import Events from '../Ui/Events'

import { FaBusinessTime } from 'react-icons/fa'

export default function Event() {
  return (
    <div className='w-[100vw]  items-center flex justify-center overflow-hidden'>
        <div className='max-w-[1400px] w-[100%] px-[2rem] py-[5rem]  flex flex-col gap-10'>
            <div className='w-[100%] items-center justify-center flex flex-col gap-3'>
            <div className="text-2xl gap-2 font-semibold justify-center uppercase tracking-wider flex items-center ">
          <FaBusinessTime className="text-4xl" /> <h1>TIMETABLE</h1>
        </div>
        <div className="text-[50px] flex gap-2  font-bold">
          <h1>Our Schedule</h1>
          <span className="text-blue-500">Plan.</span>
        </div>
            </div>
        <div className='w-[100%] flex flex-col gap-10'>

      <Events/>
      <Events/>
      <Events/>
        </div>
        </div>
    </div>
  )
}
