import React from 'react'
import { AiFillMessage } from 'react-icons/ai'
import { FaPlay } from 'react-icons/fa'
import { MdKeyboardVoice } from 'react-icons/md'

export default function Ab({setVideo}) {
  return (
    <section className='w-[100vw] flex items-center justify-center overflow-hidden'>
      <div className='w-[100%] max-w-[1400px] flex px-[2rem] py-[4rem] '>
      <div className="w-[100%] items-center justify-center text-center flex flex-col gap-3">
        <div className="text-2xl gap-2 font-semibold justify-center uppercase tracking-wider flex items-center ">
          <AiFillMessage  className="text-4xl" /> <h1>DOCUMENTATION</h1>
        </div>
        <div className="text-[50px] flex gap-2  font-bold">
          <h1>Comedy</h1>
          <span className="text-blue-500">for Fun.</span>
        </div>
        <p className="w-[60%] text-lg tracking-wider text-[#474747]">
        Ac feugiat sed lectus vestibulum mattis ullamcorper velit. Rhoncus aenean vel elit scelerisque mauris. Ornare lectus sit amet est placerat.
        </p>
     <div className='w-[100%] mt-[2rem] items-center justify-center  '>
        <div className='w-[100%]  relative h-[40rem] flex items-center justify-center rounded-xl overflow-hidden'>
        <div onClick={()=>setVideo(()=>true)} className="text-white cursor-pointer absolute text-[2rem] flex items-center justify-center w-[6rem] h-[6rem] rounded-full border-[3px] border-white">

        <FaPlay />
        </div>
        <img src={require('../Images/photo-5-1-1536x1024.jpg')} alt="" />
        </div>

     </div>
      </div>
      </div>
    </section>
  )
}
