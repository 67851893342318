import React from 'react'

export default function Services() {
  return (
    <section className='w-[100vw] flex items-center justify-center overflow-hidden'>
      <div className='w-[100%] max-w-[1400px] px-[2rem] py-[3rem]  flex '>
        <div className='w-[100%] grid grid-cols-3 gap-5'>
            <div className='bg-blue-500 rounded-xl flex flex-col gap-4 p-[2rem]'>
                <img src={require('./Images/icon-3.png')} alt="standup comedy"
                className='w-[7rem]'
                />
                <h1 className='text-[27px] font-bold text-white'>Go Premium Comedy</h1>
                <p className='text-white mt-[-10px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
            </div>
            <div className='bg-slate-100 hover:bg-blue-100 transition-all duration-200 border-[1px] border-gray-300 hover:border-blue-300 text-[#252525] rounded-xl flex flex-col gap-4 p-[2rem]'>
                <img src={require('./Images/icon-1.png')} alt="standup comedy"
                className='w-[7rem]'
                />
                <h1 className='text-[27px] font-bold '>Comedy Awards</h1>
                <p className='mt-[-10px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
            </div>
            <div className='bg-slate-100 hover:bg-blue-100 transition-all duration-200 border-[1px] border-gray-300 hover:border-blue-300 text-[#252525] rounded-xl flex flex-col gap-4 p-[2rem]'>
                <img src={require('./Images/icon-2.png')} alt="standup comedy"
                className='w-[7rem]'
                />
                <h1 className='text-[27px] font-bold '>Available on all Platforms</h1>
                <p className='mt-[-10px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>
            </div>
        </div>
      </div>
    </section>
  )
}
