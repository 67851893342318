import React from 'react'
import { AiFillMessage } from 'react-icons/ai'
import { FaPhotoFilm } from 'react-icons/fa6'
import { MdKeyboardVoice } from 'react-icons/md'
import CustomizedAccordions from './Ui/Prgressbar'

export default function Why() {
  return (
    <section className="w-[100vw] h-[100%] flex bg-blue-100 text-[#252525] items-center justify-center overflow-hidden">
    <div className="w-[100%] h-[100%] max-w-[1400px] flex flex-col justify-center items-center px-[2rem] py-[3rem]  ">
      <div className="w-[100%] items-center justify-center text-center flex flex-col gap-3">
        <div className="text-2xl gap-2 font-semibold justify-center uppercase tracking-wider flex items-center ">
          <AiFillMessage  className="text-4xl" /> <h1>simple questions</h1>
        </div>
        <div className="text-[50px] flex gap-2  font-bold">
          <h1>Answering all your</h1>
          <span className="text-blue-500">Questions.</span>
        </div>
        <p className="w-[60%] text-lg tracking-wider text-[#474747]">
        Ac feugiat sed lectus vestibulum mattis ullamcorper velit. Rhoncus aenean vel elit scelerisque mauris. Ornare lectus sit amet est placerat.
        </p>
        <div className='flex gap-3 items-center my-[1rem]'>
            <div className='w-[3rem] h-[3rem] flex items-center justify-center rounded-full text-white bg-blue-500 text-4xl'>

        <MdKeyboardVoice className='text-[25px]' />
            </div>
        <div className='text-lg  text-left '>
    
        <h1 className='font-bold text-2xl'>
+(123) 4567 7890
</h1>
<span>FOR FURTHER INQUIRY</span>
        </div>
        </div>
      </div>
      <div className="w-[80%] mt-[2rem] ">
        <CustomizedAccordions/>
      </div>
    </div>
  </section>
  )
}
