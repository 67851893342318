import React, { useState } from 'react'
import Head from '../Head'
import Banner from '../Contact/Banner'
import NewsLetter from '../Sessions/NewsLetter'
import Footers from '../Footers'
import About from './About'
import VediosPlay from './VediosPlay'
import VideoPlayer from '../About/VideoPlayer'

export default function Details() {
   const [video,setVideo]= useState(false)
  return (
    <>
      <Head/>
      <Banner name={' DETAIL VIDEO'} title={'SHOW NAME'}/>
      <About/>
      <VediosPlay setVideo={setVideo}/>
      {video && <VideoPlayer setVideo={setVideo}/>}
      <NewsLetter/>
      <Footers/>
    </>
  )
}
