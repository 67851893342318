import React from 'react'
import Head from './Head'
import Banner from './banner'
import Events from './Events'
import About from './About'
import Services from './Services'
import Anchors from './Anchors'
import Tours from './Tours'
import Testinomials from './Testinomials'
import Why from './Why'
import Companies from './Companies'
import Blogs from './Blogs'
import Footers from './Footers'

export default function Home() {
  return (
    <>
       <Head/>
 <Banner/>
 <Events/>
 <About/>
 <Services/>
 <Anchors/>
 <Tours/>
 <Testinomials/>
 <Why/>
 <Companies/>
 <Blogs/>
 <Footers/>
    </>
  )
}
