import React from 'react'
import Head from '../Head'
import Banner from './Banner'
import Contact from './Contact'
import Loaction from './Loaction'
import Footers from '../Footers'
import Foot from '../Foot'

export default function Index() {
  return (
    <div>
      <Head/>
      <Banner  name={'Contact'} title={'GET IN TOUCH'} />
      <Contact/>
      <Loaction/>
 
      <Footers/>
    </div>
  )
}
