import React from 'react'
import { FaPhoneAlt } from 'react-icons/fa'
import { IoIosHome } from 'react-icons/io'
import { MdManageHistory, MdMarkEmailUnread, MdOutlineDateRange } from 'react-icons/md'
import { RiAdminFill } from 'react-icons/ri'

export default function About() {
  return (
    <section className='w-[100vw] flex items-center justify-center overflow-hidden'>
      <div className='w-[100%] max-w-[1400px] flex px-[2rem] py-[4rem] '>
        <div className='w-[100%] flex gap-5 '>
            <div className='w-[65%] flex flex-col gap-5'>
                <h1 className='text-5xl font-bold'>
                Friends having party in a nightclub
                </h1>
                <div className='flex gap-5 items-center text-md font-bold text-[#252525]'>
                    <div className='flex gap-2 items-center'>
                    <RiAdminFill />  <h1>
                        Admin</h1>
                    </div>
                    <div className='flex gap-2 items-center'>
                    <MdOutlineDateRange /> <h1>
                        2024/03/31</h1>
                    </div>
                    <div className='flex gap-2 items-center'>
                    <MdManageHistory /> <h1>
                        History</h1>
                    </div>

                </div>
                <div className='rounded-xl  overflow-hidden w-[100%]'>
                    <img src={require('../Images/photo-5-1-1536x1024.jpg')} alt="" />

                </div>
                <p>
                Dolor sed viverra ipsum nunc aliquet bibendum enim facilisis. Vestibulum lorem sed risus ultricies tristique nulla aliquet enim. Eu scelerisque felis imperdiet proin fermentum leo vel orci porta. Malesuada bibendum arcu vitae elementum curabitur vitae nunc sed velit. Nisi est sit amet facilisis magna etiam. Platea dictumst vestibulum rhoncus est pellentesque elit ullamcorper. Eleifend mi in nulla posuere sollicitudin aliquam ultrices. In vitae turpis massa sed elementum. Aliquet bibendum enim facilisis gravida neque convallis a cras. Ut placerat orci nulla pellentesque dignissim enim sit amet venenatis. Mauris cursus mattis molestie a iaculis at erat. Eu mi bibendum neque egestas congue quisque egestas diam. Egestas fringilla phasellus faucibus scelerisque eleifend donec pretium.
                </p>
                    <h1 className='text-2xl font-semibold uppercase'>
                    Sit amet massa vitae tortor condimentum lacinia quis vel.
                    </h1>
                    <p>Sit amet aliquam id diam maecenas ultricies mi eget. Sed id semper risus in hendrerit gravida rutrum quisque non. Dolor sit amet consectetur adipiscing. Nunc scelerisque viverra mauris in aliquam sem fringilla. Aliquam sem et tortor consequat id porta nibh venenatis cras. Ultrices in iaculis nunc sed augue lacus. Arcu ac tortor dignissim convallis aenean et tortor. Neque sodales ut etiam sit amet nisl purus.</p>
                    <h1 className='text-2xl font-semibold uppercase'>
                    Amet justo donec enim diam vulputate ut pharetra sit amet.
                    </h1>
                    <p>Nulla posuere sollicitudin aliquam ultrices sagittis orci. Cursus euismod quis viverra nibh cras pulvinar mattis nunc sed. Eget nunc scelerisque viverra mauris in aliquam. Euismod in pellentesque massa placerat duis ultricies lacus sed turpis. Enim lobortis scelerisque fermentum dui. Scelerisque viverra mauris in aliquam.  </p>
            </div>
            <div className='w-[35%] flex flex-col gap-7'>
               
                <div className='w-[100%] flex flex-col gap-5   bg-blue-100 border-[1px] border-gray-300 rounded-xl p-3 '>
                   <a href="/blog/1">
                    <div className='flex gap-5 w-[100%]'>
                        <img src={require('../Images/photo-5-1-1536x1024.jpg')} alt="" className='w-[40%]'/>
                            <h1 className='text-xl font-bold'>Bated breath while watch very exciting football</h1>
                    </div>
                   </a>
                   <a href="/blog/1">
                    <div className='flex gap-5 w-[100%]'>
                        <img src={require('../Images/photo-5-1-1536x1024.jpg')} alt="" className='w-[40%]'/>
                            <h1 className='text-xl font-bold'>Bated breath while watch very exciting football</h1>
                    </div>
                   </a>
                   <a href="/blog/1">
                    <div className='flex gap-5 w-[100%]'>
                        <img src={require('../Images/photo-5-1-1536x1024.jpg')} alt="" className='w-[40%]'/>
                            <h1 className='text-xl font-bold'>Bated breath while watch very exciting football</h1>
                    </div>
                   </a>
                </div>

                <div className='w-[100%] flex flex-col gap-5   bg-blue-100 border-[1px] border-gray-300 rounded-xl p-3 '>
               <div className='w-[100%] flex flex-col gap-2 items-center justify-center'>
                <h1 className='text-2xl font-bold'>LET US HELP YOU!</h1>
                <p className='w-[90%] text-center'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec.
                </p>
               </div>
                <div className="w-[100%]  flex flex-col  gap-0">
            <div className="flex gap-3 bg-blue-100 border-[#bbbbbb] border-[1px] rounded-xl px-4 py-3 items-center my-[1rem]">
            <div className="w-[4rem] h-[4rem] flex items-center justify-center rounded-xl text-white bg-blue-600 text-4xl">
              <IoIosHome className="text-[35px]" />
            </div>
            <div className="text-lg  text-left ">
              <h1 className="font-bold">LOCATION ADDRESS</h1>
              <span>KLLG st, No 99,Pku City,</span>
            </div>
          </div>

          <div className="flex gap-3 bg-blue-100 border-[1px] border-[#bbbbbb] rounded-xl px-4 py-3 items-center my-[1rem]">
            <div className="w-[4rem] h-[4rem] flex items-center justify-center rounded-xl text-white bg-blue-600 text-4xl">
              <FaPhoneAlt  className="text-[35px]" />
            </div>
            <div className="text-lg  text-left ">
              <h1 className="font-bold">
              CONSULTATION</h1>
              <span>0761-8523-398</span>
            </div>
          </div>
          <div className="flex gap-3 bg-blue-100 border-[1px] border-[#bbbbbb] rounded-xl px-4 py-3 items-center my-[1rem]">
            <div className="w-[4rem] h-[4rem] flex items-center justify-center rounded-xl text-white bg-blue-600 text-4xl">
              <MdMarkEmailUnread  className="text-[35px]" />
            </div>
            <div className="text-lg  text-left ">
              <h1 className="font-bold">EMAIL ADDRESS</h1>
              <span>hello@domainsite.com</span>
            </div>
          </div>
            </div>
                </div>
            </div>
        </div>
      </div>
    </section>
  )
}
