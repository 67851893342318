import React from 'react'

export default function VideoPlayer({setVideo}) {
  return (
    <div style={{zIndex:99999999}} className='w-[100vw] h-[100vh] fixed  top-0 items-center flex justify-center backdrop-blur-lg overflow-hidden '>
        <div onClick={()=>setVideo(()=>false)} className='absolute top-[2rem] cursor-pointer text-[2rem] font-bold right-[5%]'>
            X
        </div>
        <div className='max-w-[1400px] w-[100%] h-[30rem] mx-[10%] my-[3rem]  bg-white'>

            </div> 
      
    </div>
  )
}
