import React from "react";
import { FaFacebook, FaPlay, FaPlayCircle, FaRegClock, FaTelegram, FaUserFriends } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { IoLogoTwitter } from "react-icons/io";
import { LuCalendarClock } from "react-icons/lu";
import Shows from "../Ui/Shows";

export default function VediosPlay({ setVideo }) {
  return (
    <section className="w-[100vw] flex items-center justify-center overflow-hidden ">
      <div className="w-[100%] flex max-w-[1400px] px-[2rem] py-[3rem]">
        <div className="flex w-[100%] gap-5">
          <div className="w-[65%] border-[1px] border-gray-300 flex flex-col gap-5 rounded-xl p-[2rem] bg-blue-100">
            <div className="w-[100%] flex items-center justify-center  rounded-xl relative overflow-hidden">
              <img src={require("../Images/photo-5-1-1536x1024.jpg")} alt="" />
              <div
                onClick={() => setVideo(() => true)}
                className="text-white cursor-pointer absolute text-[2rem] flex items-center justify-center w-[6rem] h-[6rem] rounded-full border-[3px] border-white"
              >
                <FaPlay />
              </div>
            </div>
            <h1 className="text-4xl font-bold text-[#252525]">Live Stand-Up Comedy Performances</h1>
            <div className="flex items-center gap-3">
                <div className="flex gap-2 items-center">
                <FaUserFriends /> <h1 className="text-md font-bold">
                    ZEN EVANA</h1>
                </div>
                <div className="flex gap-2 items-center">
                <FaRegClock />  <h1 className="text-md font-bold">
                    ZEN EVANA</h1>
                </div>
            </div>
            <p className="">Nulla facilisi etiam dignissim diam quis enim lobortis scelerisque. Euismod lacinia at quis risus sed vulputate odio. Vestibulum sed arcu non odio euismod lacinia at quis risus.</p>
          
                <div className="-mt-2  text-xl flex gap-7 rounded-xl text-blue-600 py-3">
              <span>
                <FaSquareInstagram />
              </span>
              <span>
                <FaTelegram />
              </span>
              <span>
                <FaFacebook />
              </span>
              <span>
                <IoLogoTwitter />
              </span>
            </div>
          
          </div>
          <div className="w-[35%] bg-blue-100 border-[1px] border-gray-300 rounded-xl p-[1rem]   ">
            <div className="w-[100%] flex flex-col  gap-5">
              <a href="/show/1">
               <Shows setVideo={setVideo} />
              </a>
              <a href="/show/1">
               <Shows setVideo={setVideo} />
              </a>
              <a href="/show/1">
               <Shows setVideo={setVideo} />
              </a>
              <a href="/show/1">
               <Shows setVideo={setVideo} />
              </a>

            </div>

          </div>
        </div>
      </div>
    </section>
  );
}
