import React from "react";
import { FaPlus } from "react-icons/fa";

export default function Events() {
  return (
    <section className="w-[100vw] mt-[-20rem] text-white h-[100%] flex items-center justify-center overflow-hidden">
      <div className="max-w-[1400px] w-[100%] h-[100%] flex justify-start items-center px-[2rem] pt-[10rem]">
        <div className="w-[100%] justify-center flex items-center gap-5">
          <div className="w-[50%] h-[21rem]  border-[2px] bg-[#fed32e] px-[2rem] p-[1rem] border-[#252525] rounded-2xl">
            <div className="w-[100%] gap-5 flex items-center justify-around">
              <div className="w-[50%] flex flex-col gap-4">
                <div className="text-white bg-blue-500 items-center text-center leading-[0.9rem] uppercase tracking-wider font-semibold w-fit px-5 py-2 ">
                  October 28, <br />
                  2024
                </div>
                <h1 className="text-2xl text-[#252525] font-bold tracking-wider ">
                  A NEW STANDUP
                </h1>
                <p className="text-lg text-[#535353] leading-[1.4rem] tracking-wider ">
                  Convallis posuere morbi leo urna molestie at elementum eu.
                  Vehicula ipsum a arcu cursus.
                </p>
                <div className="bg-blue-500 border-[0.2px] border-transparent hover:border-blue-500 hover:bg-transparent hover:text-blue-500 rounded-md transition-all duration-200 cursor-pointer  px-5 py-2 w-fit text-lg font-bold tracking-wider flex items-center gap-2 uppercase">
                  <h1>Read More</h1>
                  <FaPlus />
                </div>
              </div>
              <div className="w-[50%] relative">
                <img
                  src={require("./Images/photo-1-1.png")}
                  className="mt-[-9rem] "
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="w-[50%] h-[21rem] border-[2px] bg-[#ff685d]   px-[2rem] py-[2rem] border-[#252525] rounded-2xl">
            <div className="w-[100%] gap-2 flex items-center justify-around">
              <div className="w-[50%] flex flex-col gap-4">
                <div className="text-[#252525] bg-[#fed32e] items-center text-center leading-[0.9rem] uppercase tracking-wider font-semibold w-fit px-5 py-2 ">
                  October 28, <br />
                  2024
                </div>
                <h1 className="text-2xl font-bold tracking-wide ">
                  BEST-SELLING BOOK
                </h1>
                <p className="text-lg  leading-[1.4rem] tracking-wider ">
                  Convallis posuere morbi leo urna molestie at elementum eu.
                  Vehicula ipsum a arcu cursus.
                </p>
                <div className="bg-blue-500 border-[0.2px] border-transparent hover:border-blue-500 hover:bg-transparent hover:text-blue-500 rounded-md transition-all duration-200 cursor-pointer  px-5 py-2 w-fit text-lg font-bold tracking-wider flex items-center gap-2 uppercase">
                  <h1>Read More</h1>
                  <FaPlus />
                </div>
              </div>
              <div className="w-[50%] relative">
                <img src={require("./Images/book.png")} className=" " alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
