import React from 'react'

export default function NewsLetter() {
  return (
    <section className='w-[100vw] flex items-center justify-center overflow-hidden'>
        <div className='w-[100%] max-w-[1400px] gap-4 text-white flex-col mx-[2rem] my-[6rem] flex justify-center items-center rounded-xl py-[5rem] bg-blue-600 '>
            <h1 className='text-5xl font-bold text-white  tracking-wider'>
            Newsletter Signup
            </h1>
            <p className='mt-3'>Nec ullamcorper sit amet risus. Sagittis purus sit amet volutpat consequat mauris</p>
            <form action="" className='flex w-[100%] flex-col gap-3 items-center justify-center'>
                <input type="email" name='email' placeholder='Email' className='w-[50%]  outline-none rounded-md px-3 py-3 ' />
                <button className='w-[50%] py-3 border-blue-500 border-[2px] hover:bg-blue-800 transition-all duration-200 rounded-md text-center font-bold uppercase'> 
                    Subscribe
                </button>
            </form>
        </div>
      
    </section>
  )
}
