import React from "react";
import { AiFillMessage } from "react-icons/ai";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosHome } from "react-icons/io";
import { MdKeyboardVoice, MdMarkEmailUnread } from "react-icons/md";

export default function Contact() {
  return (
    <section className="w-[100vw] h-[100%] flex text-[#252525] items-center justify-center overflow-hidden">
      <div className="w-[100%] h-[100%] max-w-[1400px] flex flex-col justify-center items-center px-[2rem] py-[3rem]  ">
        <div className="w-[100%] items-center justify-center text-center flex flex-col gap-3">
          <div className="text-2xl gap-2 font-semibold justify-center uppercase tracking-wider flex items-center ">
            <AiFillMessage className="text-4xl" /> <h1>GET IN TOUCH</h1>
          </div>
          <div className="text-[50px] flex gap-2  font-bold">
            <h1>Let's Keep inr</h1>
            <span className="text-blue-500">Touch.</span>
          </div>
          <p className="w-[60%] text-lg tracking-wider text-[#474747]">
            Ac feugiat sed lectus vestibulum mattis ullamcorper velit. Rhoncus
            aenean vel elit scelerisque mauris. Ornare lectus sit amet est
            placerat.
          </p>
            <div className="w-[100%] items-center  flex justify-center gap-5">
            <div className="flex gap-3 bg-blue-100 border-[#bbbbbb] rounded-xl px-4 py-3 items-center my-[1rem]">
            <div className="w-[4rem] h-[4rem] flex items-center justify-center rounded-xl text-white bg-blue-600 text-4xl">
              <IoIosHome className="text-[35px]" />
            </div>
            <div className="text-lg  text-left ">
              <h1 className="font-bold">LOCATION ADDRESS</h1>
              <span>KLLG st, No 99,Pku City,</span>
            </div>
          </div>

          <div className="flex gap-3 bg-blue-100 border-[#bbbbbb] rounded-xl px-4 py-3 items-center my-[1rem]">
            <div className="w-[4rem] h-[4rem] flex items-center justify-center rounded-xl text-white bg-blue-600 text-4xl">
              <FaPhoneAlt  className="text-[35px]" />
            </div>
            <div className="text-lg  text-left ">
              <h1 className="font-bold">
              CONSULTATION</h1>
              <span>0761-8523-398</span>
            </div>
          </div>
          <div className="flex gap-3 bg-blue-100 border-[#bbbbbb] rounded-xl px-4 py-3 items-center my-[1rem]">
            <div className="w-[4rem] h-[4rem] flex items-center justify-center rounded-xl text-white bg-blue-600 text-4xl">
              <MdMarkEmailUnread  className="text-[35px]" />
            </div>
            <div className="text-lg  text-left ">
              <h1 className="font-bold">EMAIL ADDRESS</h1>
              <span>hello@domainsite.com</span>
            </div>
          </div>
            </div>
        <div className="w-[100%] mt-[2rem] flex items-center justify-center">
            <form action="" className="w-[80%] flex flex-col gap-8">
                <div className="flex gap-4 items-center">
                    <input type="text" placeholder="First Name" name="name"
                    className="outline-none border-[1px] border-[#b4b4b4] rounded-md px-2 w-[50%] py-3"
                    required
                    />
                     <input type="text" placeholder="Last Name" name="last name"
                    className="outline-none border-[1px] border-[#b4b4b4] rounded-md px-2 w-[50%] py-3"
                    required
                    />

                </div>
                <input type="email" placeholder="Email" name="Email"
                    className="outline-none border-[1px] border-[#b4b4b4] rounded-md px-2 w-[100%] py-3"
                    required
                    />
                    <select  className="outline-none border-[1px] border-[#b4b4b4] rounded-md px-2 w-[100%] py-3"
                    required>
                        <option value="">Please Choose Option</option>
                        <option value="Service">Services</option>
                        <option value="Price">Price</option>

                        <option value="Other">Other</option>

                    </select>
                    <textarea name="message" placeholder="Message" id="" rows={5}
                    className="outline-none border-[1px] border-[#b4b4b4] rounded-md px-2 w-[100%] py-3"
                    ></textarea>
                    <button className="w-[100%] border-[1px] rounded-md transition-all duration-200 border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white font-bold text-lg uppercase tracking-wider text-cebter py-3">
                        Get free a Quote
                    </button>
            </form>

        </div>
        
        </div>
      </div>
    </section>
  );
}
