import React from "react";
import { FaFacebook, FaLongArrowAltRight, FaTelegram } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { IoLogoTwitter, IoMdMail } from "react-icons/io";

export default function Foot() {
  return (
    <footer className="w-[100vw] text-white h-[100%] flex items-center bg-[#003661] justify-center overflow-hidden ">
      <div className="max-w-[1400px] w-[100%] px-[2rem] py-[5rem]  ">
        <div className="w-[1005] flex justify-between gap-10 items-start">
          <div className="w-[30%] flex flex-col gap-4">
            <h1 className="text-3xl font-bold ">SPOT LIGHT</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
              tellus.
            </p>
            <p className="text-2xl font-bold">READY APPS NOW!</p>
            <div className="-mt-2  text-xl flex gap-7 w-[100%] rounded-xl text-white py-3">
              <span>
                <FaSquareInstagram />
              </span>
              <span>
                <FaTelegram />
              </span>
              <span>
                <FaFacebook />
              </span>
              <span>
                <IoLogoTwitter />
              </span>
            </div>
          </div>
          <div className="w-[65%]">
            <div className="w-[100%] flex gap-5">
              <div className="w-[33%] flex flex-col gap-2">
                <h1 className="text-2xl font-bold">USEFULL LINK</h1>
                <div className="flex flex-col gap-3 text-lg tracking-wider ">
                  <span>About Me</span>
                  <span>Our Schedule</span>
                  <span>Our Show</span>
                  <span>Single Show</span>
                  <span>Article & News</span>
                  <span>Contact Us</span>
                </div>
              </div>
              <div className="w-[33%] flex flex-col gap-3">
                <h1 className="text-2xl font-bold">INVITED GUEST?</h1>
                <p>
                  Ut elit tellus, luctus nec ullamcorper mattis, pulvinar
                  dapibus leo.
                </p>
                <div className="text-center flex gap-3">
                  <div className="p-2 bg-blue-600 rounded-full text-xs w-fit">
                    <FaLongArrowAltRight />
                  </div>
                  <h1 className="text-lg  capitalize">see open guests</h1>
                </div>
                <h1 className="text-2xl font-bold">JOB OPPORTUNITY</h1>
                <div className="text-center flex gap-3">
                  <div className="p-2 bg-blue-600 rounded-full text-xs w-fit">
                    <FaLongArrowAltRight />
                  </div>
                  <h1 className="text-lg capitalize">Interested Working Us</h1>
                </div>
              </div>
              <div className="w-[33%] flex flex-col gap-2">
                <h1 className="text-2xl font-bold">OFFICE CONTACT</h1>
                <p className="text-lg leading-[2rem] ">
                  VeerNarayana temple, No 99,
                  <br />
                  Gadaga City, 582101 Big City
                  <br /> Karnatata india
                </p>
                <div className="flex items-center gap-2">
                  <IoMdMail className="text-sm text-blue-600" />
                  <h1>hello@awesomesite@mail.com</h1>
                </div>
                <div className="-mt-0  text-xl flex gap-7 w-[100%] rounded-xl text-white py-3">
                  <span>
                    <FaSquareInstagram />
                  </span>
                  <span>
                    <FaTelegram />
                  </span>
                  <span>
                    <FaFacebook />
                  </span>
                  <span>
                    <IoLogoTwitter />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
