import React from 'react'
import Foot from './Foot'

export default function Footers() {
  return (
    <>
    <Foot/>
    <div className='text-white w-[100vw]  bg-blue-500 text-center py-3 text-lg font-semibold'>
      <h1>Copyright © 2024 ABC. All Rights Reserved</h1>
    </div>
    </>
  )
}
