import React from "react";

import { VscWorkspaceTrusted } from "react-icons/vsc";
import Test from "./Ui/Test";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';



// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export default function Testinomials() {
  return (
    <section className="w-[100vw] h-[100%] flex text-white items-center justify-center px-[1rem] pr-[2rem] mb-[2rem] overflow-hidden">
      <div className="max-w-[1400px] w-[100%] p-[3rem] rounded-xl bg-blue-600">
        <div className="w-[100%] flex items-center gap-10">
          <div className="w-[50%] flex flex-col gap-5">
            <div className="text-2xl font-bold uppercase flex gap-2 items-center">
              <VscWorkspaceTrusted className="text-3xl" />
              <h1>trusted</h1>
            </div>
            <div className="text-[2.8rem] leading-[3.2rem] font-bold flex flex-col">
              <h1>Create an Vibe by</h1>
              <h1>Offering Something Funny.</h1>
            </div>
            <p className=" tracking-wide text-lg">
              Nulla facilisi etiam dignissim diam quis enim lobortis
              scelerisque. Euismod lacinia at quis risus sed vulputate odio.
              Vestibulum sed arcu non odio euismod lacinia at quis risus. Ac
              feugiat sed lectus vestibulum mattis ullamcorper velit.
            </p>
            <div className="flex items-center gap-7">
                <div className="flex items-center gap-1">
                    <div className="w-[3.5rem] h-[3.5rem] rounded-full items-center flex justify-center border-[3px] overflow-hidden object-cover border-white">
                        <img src={require('./Images/photo-3-1.jpg')} className="w-[100%] h-[100%] " alt="" />
                  </div>
                  <div className="w-[3.5rem] h-[3.5rem] rounded-full items-center flex justify-center border-[3px] overflow-hidden object-cover border-white">
                        <img src={require('./Images/photo-3-1.jpg')} className="w-[100%] h-[100%] " alt="" />
                  </div>
                  <div className="w-[3.5rem] h-[3.5rem] rounded-full items-center flex justify-center border-[3px] overflow-hidden object-cover border-white">
                        <img src={require('./Images/photo-3-1.jpg')} className="w-[100%] h-[100%] " alt="" />
                  </div>
                  <div className="w-[3.5rem] h-[3.5rem] rounded-full items-center flex justify-center border-[3px] overflow-hidden object-cover border-white">
                        <img src={require('./Images/photo-3-1.jpg')} className="w-[100%] h-[100%] " alt="" />
                  </div>
               
                </div>
                <div className="leading-[1.1rem]">
                    <h1 className="uppercase font-semibold text-sm tracking-wide">Happy Customer</h1>
                    <span className="text-gray-300 uppercase tracking-wider text-sm">
                    ( 1.2K Review )
                    </span>
                </div>

            </div>
          </div>
          <div className="w-[50%] relative flex gap-4 items-center justify-center" >
          <div style={{zIndex:9999}} className="absolute z-999 w-[100%] top-[4.5rem]  flex justify-between items-center ">
              <div
                transition={{ duration: 0.7 }}
                className="  px-[1rem] w-fit bg-[#012073] py-[1rem] rounded-full"
              >
                <FaChevronLeft className="text-[1.5rem] custom-prev text-white" />
              </div>
              <div
                transition={{ duration: 0.7 }}
                className="  px-[1rem] w-fit bg-[#012073] py-[1rem] rounded-full"
              >
                <FaChevronRight className="text-[1.5rem] custom-next text-white" />
              </div>
            </div>
            <div className="w-[100%] flex items-center justify-center">

          <Swiper
        spaceBetween={10}
        centeredSlides={true}
        navigation={{
            nextEl: ".custom-next",
            prevEl: ".custom-prev",
          }}
          loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        
        modules={[Autoplay, Pagination, Navigation]}
        className="w-[100%] flex items-center justify-center "
      >
        <SwiperSlide>

           <Test/>
        </SwiperSlide>
        <SwiperSlide>

           <Test/>
        </SwiperSlide>
        <SwiperSlide>

           <Test/>
        </SwiperSlide>
</Swiper>
            </div>
          </div>
        </div>
        <div className="border-t-[1px] border-white  my-[2rem]"/>
        <div className="flex gap-28 items-center justify-center py-[1rem] w-[100%]">
            <div className=" flex flex-col gap-2 leading-[2rem] ">
                <h1 className="text-[2.8rem] leading-[2ren] font-bold">
                    10+
                </h1>
                <span className="text-lg uppercase font-bold">SPECIAL SHOW</span>
            </div>
            <div className="flex flex-col gap-2 leading-[2rem] ">
                <h1 className="text-[2.8rem] leading-[2ren] font-bold">
                    10%
                </h1>
                <span className="text-lg uppercase font-bold">SATISFIED AUDIENCE</span>
            </div>
            <div className="flex flex-col gap-2 leading-[2rem] ">
                <h1 className="text-[2.8rem] leading-[2ren] font-bold">
                    10
                </h1>
                <span className="text-lg uppercase font-bold">AWARDS</span>
            </div>
            <div className="flex flex-col gap-2 leading-[2rem] ">
                <h1 className="text-[2.8rem] leading-[2ren] font-bold">
                    100
                </h1>
                <span className="text-lg uppercase font-bold">SPONSOR</span>
            </div>

        </div>
      </div>
    </section>
  );
}
