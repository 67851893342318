import React, { useState } from "react";
import { FaAward, FaPlus, FaUser } from "react-icons/fa";
import { FaCircleUser } from "react-icons/fa6";
import { HiSpeakerphone } from "react-icons/hi";

export default function About() {
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  return (
    <section className="w-[100vw] h-[1005] text-[#252525] flex justify-center items-center overflow-hidden">
      <div className="w-[100%] px-[2rem] py-[3rem] pt-[7rem] max-w-[1400px] flex justify-center items-center">
        <div className="w-[100%] flex items-center justify-center gap-5">
          <div className="w-[50%]">
            <div className="w-[100%] flex flex-col gap-4">
              <div className="text-2xl tracking-wider gap-2 flex items-center uppercase font-bold">
                <FaCircleUser />
                <h1>About me</h1>
              </div>
              <div className="flex text-[2.7rem]  tracking-wide font-bold flex-col leading-[3.2rem]">
                <h1>Need Funny Comedian ?</h1>
                <h1 className="text-blue-500">I Can Help You!</h1>
              </div>
              <p className="text-md r">
                Amet consectetur adipiscing elit pellentesque habitant morbi
                tristique. Et malesuada fames ac turpis egestas. Volutpat odio
                facilisis mauris sit amet massa vitae tortor condimentum. Nisi
                porta lorem mollis aliquam ut porttitor leo a diam. Fermentum
                odio eu feugiat pretium nibh ipsum consequat nisl vel. Ut ornare
                lectus sit amet.
              </p>
              <p className="text-md">
                Lorem sed risus ultricies tristique. Suscipit tellus mauris a
                diam maecenas. Pharetra magna ac placerat vestibulum.
              </p>
              <div className="hover:bg-blue-500 border-[1px] mt-[1rem] hover:text-white  border-blue-500  bg-transparent text-blue-500 rounded-md transition-all duration-200 cursor-pointer  px-5 py-2 w-fit text-lg font-bold tracking-wider flex items-center gap-2 uppercase">
                <h1>Read More</h1>
                <FaPlus />
              </div>
              <div className="w-[100%]  border-t-[1px] mt-[1rem] border-slate-300" />
              <div className="w-[100%] text-sm mt-2 flex gap-2 items-center justify-center ">
                <div
                  onClick={() => {
                    setSecond(() => false);
                    setThird(() => false);
                    setFirst(true);
                  }}
                  className={`${
                    first
                      ? "bg-blue-500 text-white"
                      : "bg-slate-300 text-blue-500"
                  }  w-[100%] text-center py-3 rounded-md`}
                >
                  <h1 className=" tracking-wider font-semibold flex justify-center items-center gap-1"><FaUser /> PROFILE</h1>
                </div>
                <div
                  onClick={() => {
                    setFirst(() => false);
                    setThird(() => false);
                    setSecond(true);
                  }}
                  className={`${
                    second
                      ? "bg-blue-500 text-white"
                      : "bg-slate-300 text-blue-500"
                  }  w-[100%] text-center py-3 rounded-md`}
                >
                     <h1 className=" tracking-wider font-semibold flex justify-center items-center gap-1"><FaAward />  AWARDS</h1>
                </div>
                <div
                  onClick={() => {
                    setFirst(() => false);
                    setSecond(() => false);
                    setThird(true);
                  }}
                  className={`${
                    third
                      ? "bg-blue-500 text-white"
                      : "bg-slate-300 text-blue-500"
                  }  w-[100%] text-center py-3 rounded-md`}
                >
                    <h1 className=" tracking-wider font-semibold flex justify-center items-center gap-1"><HiSpeakerphone /> VISION & MISSION</h1>
                </div>
              </div>
              <div className="p-[1rem] border-[0.2px] rounded-xl border-blue-400 ">
                {first && (
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                    id sapien ultricies, varius augue eu, tincidunt urna. Donec
                    nec nisl consectetur, sagittis leo quis, dignissim lacus.
                    Suspendisse maximus, lorem at porttitor lacinia.
                  </p>
                )}
                {second && (
                  <p>
                    Quisque id rutrum tortor. Duis a ornare leo, at consequat
                    leo. Morbi non ornare nisi. Nam eu accumsan sem, ut posuere
                    augue. Morbi lacinia nulla metus, quis cursus velit lacinia
                    vel. Vestibulum tristique et purus eget lobortis.
                  </p>
                )}
                {third && (
                  <p>
                    Suspendisse maximus, lorem at porttitor lacinia, tortor ante
                    aliquam purus, molestie lacinia dolor nibh ut ex. Quisque id
                    rutrum tortor. Duis a ornare leo, at consequat leo. Morbi
                    non ornare nisi. Nam eu accumsan sem, ut posuere augue.
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="w-[50%] mt-[10rem] ">
            <div className="w-[100%] h-[100%] flex flex-col relative items-center justify-center">
                <img src={require('./Images/background-3.png')} alt="comedy" className="w-[80%] z-[-10]"  />
                <img src={require('./Images/photo-2-2.png')} alt="standout comedy" className="
                absolute bottom-0  w-[100%] left-0
                " />
                <img src={require('./Images/photo-3-1.jpg')} alt="standout comedy"
                className="absolute z-20  w-[40%] border-[10px] bottom-[-7px] right-0 rounded-xl overflow-hidden border-white "
                />
                <div className="w-[50%] bg-slate-200 rounded-xl items-center absolute left-[2rem] border-[#a7a5a5] border-[0.1px] bottom-[-1rem] p-[1rem] flex ">
                    <img src={require('./Images/icon-4.png')} alt="" className="w-[5rem]"/>
                    <div>
                        <h1 className="text-3xl font-bold text-blue-500 leading-[1.8rem]">BEST ALBUMS</h1>
                        <h1 className="text-2xl tracking-wider ">1+ Millions Copy</h1>
                    </div>

                </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
