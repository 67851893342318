import React from 'react'
import { FaLocationDot } from 'react-icons/fa6'
import { IoIosTime } from 'react-icons/io'

export default function Tour({color,title}) {
   console.log(color)
  return (
    <div className='border-[#e0dddd]  border-[1px] p-[1rem] rounded-xl bg-slate-100'>
     <div className='w-[100%] flex  gap-5 items-center'>
        <div className={`p-3 rounded-xl w-[20%] text-center flex flex-col ${color}`}>
            <h1 className='text-[3rem] font-bold text-white'>20</h1>
            <span className='text-lg font-semibold mt-[-5px] text-white tracking-wide'>APR</span>
        </div>
        <div className='flex flex-col gap-2'> 
            <h1 className='text-2xl font-[650]'>{title}</h1>
            <p className='text-lg tracking-wide mt-2'>Nec ullamcorper sit amet risus nullam eget felis.</p>
            <div className='flex  gap-4 items-center'>
             <div className='flex gap-2 items-center text-md '>
             <FaLocationDot  className='text-gray-400'/> <h1 className='text-lg text-gray-500 font-bold'>
                
                Hubli, Karnataka
                </h1>
             </div>
             <div className='flex gap-2 items-center text-md '>
             <IoIosTime  className='text-gray-400'/> <h1 className='text-lg text-gray-500 font-bold'>
             08 AM - 10 AM
                </h1>
             </div>
            </div>
        </div>
     </div>
    </div>
  )
}
