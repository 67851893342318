import React from 'react'
import { FaCalendarAlt } from 'react-icons/fa'
import { FaLocationDot } from 'react-icons/fa6'
import { IoIosTime } from 'react-icons/io'

export default function Events({color,title}) {
   console.log(color)
  return (
    <div className='border-[#e0dddd]  border-[1px] p-[1rem] rounded-xl bg-slate-100'>
     <div className='w-[100%] flex  gap-5 items-center'>
        <div className={`p-3 rounded-2xl w-[30%] overflow-hidden text-center flex flex-col ${color}`}>
           <img src={require('../Images/photo-5-1-1536x1024.jpg')} className='w-[100%] rounded-xl h-[100%]' alt="" />
        </div>
        <div className='flex w-[50%] flex-col gap-2'> 
            <h1 className='text-2xl font-[650]'>
            Live Stand-Up Comedy Performances</h1>
            <p className='text-lg tracking-wide mt-2'>Morbi quis commodo odio aenean sed adipiscing. Viverra accumsan in nisl nisi scelerisque eu ultrices vitae. Sed adipiscing diam donec adipiscing. In fermentum et sollicitudin ac orci phasellus egestas tellus. Arcu vitae elementum curabitur vitae nunc sed.</p>
            <div className='flex  gap-4 items-center'>
             <div className='flex gap-2 items-center text-md '>
             <FaLocationDot  className='text-gray-400'/> <h1 className='text-lg text-gray-500 font-bold'>
                
                Hubli, Karnataka
                </h1>
             </div>
             <div className='flex gap-2 items-center text-md '>
             <IoIosTime  className='text-gray-400'/> <h1 className='text-lg text-gray-500 font-bold'>
             08 AM - 10 AM
                </h1>
             </div>
             <div className='flex gap-2 items-center text-md '>
              <FaCalendarAlt className='text-gray-400'/> <h1 className='text-lg text-gray-500 font-bold'>   
20 APRIL 2024
                </h1>
             </div>
          
            </div>
        </div>
        <div className='w-[15%] hover:text-white hover:bg-blue-500 transition-all duration-200 border-blue-500 rounded-lg border-[2px] text-center text-blue-500 uppercase font-bold tracking-wider px-2 py-3'>

Read more
        </div>
     </div>
    </div>
  )
}
