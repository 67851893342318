import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Home";
import Index from "./Components/Contact/Index";
import Abouts from "./Components/About/Abouts";
import Schedules from "./Components/Schedule/Schedules";
import Session from "./Components/Sessions/Session";
import Blogss from "./Components/Blogs/Blogss";
import Details from "./Components/ShowDetails/Details";
import BlogDetail from "./Components/BlogsDeatils/Details";

function App() {
  return (
    <>
   <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/contact" element={<Index/>}/>
      <Route path="/about" element={<Abouts/>}/>
      <Route path="/schedule" element={<Schedules/>}/>
      <Route path="/show" element={<Session/>}/>
      <Route path="/blog" element={<Blogss/>}/>
      <Route path="/blog/1" element={<BlogDetail/>}/>

      <Route path="/show/:id" element={<Details/>}/>


    </Routes>
   </BrowserRouter>
    </>
  );
}

export default App;
