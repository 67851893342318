import React from 'react'
import Head from '../Head'
import Banner from '../Contact/Banner'
import Blogs from '../Blogs'
import Footers from '../Footers'
import NewsLetter from '../Sessions/NewsLetter'

export default function Blogss() {
  return (
    <>
      <Head/>
      <Banner name={' ARTICLE & NEWS'} title={'OUR BLOG'}/>
      <Blogs/>
      <NewsLetter/>
      <Footers/>
    </>
  )
}
