import React from 'react'
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa'

export default function Test() {
  return (
    <div className="w-[80%] relative flex flex-col ml-[10%] items-center justify-center gap-5">
    <FaQuoteLeft  className='absolute top-0 left-0 text-[4rem]'/>
    <FaQuoteRight   className='absolute bottom-0 right-0 text-[4rem]'/>
        <img src={require('../Images/photo-3-1.jpg')}
        className="w-[6rem] h-[6rem] rounded-full border-[2px] border-white"
        alt="" />
        <p className="text-center">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus esse earum beatae voluptatum aliquid, quia iste, illo vero, minus voluptas possimus doloremque similique facilis iusto officiis maxime ducimus provident laboriosam!
        </p>
        <div className="w-[100%] text-md font-bold uppercase leading-[0.7rem] flex flex-col gap-2 items-center justify-center">
            <h1>consectetur</h1>
            <span>Web developer</span>

        </div>
    </div>
  )
}
